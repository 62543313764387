import { useInputControl } from '@conform-to/react';
import React, { useId } from 'react';
import { type IconName } from '@/icon-name';
import { MultiSelect, type MultiSelectProps } from './multi-select.tsx';
import { Checkbox, type CheckboxProps } from './ui/checkbox.tsx';
import { Icon } from './ui/icon.tsx';
import { Input } from './ui/input.tsx';
import { Label } from './ui/label.tsx';
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from './ui/select.tsx';
import { Textarea } from './ui/textarea.tsx';
import {
  Tooltip,
  TooltipContent,
  TooltipProvider,
  TooltipTrigger,
} from './ui/tooltip.tsx';

export type ListOfErrors = Array<string | null | undefined> | null | undefined;

export function ErrorList({
  id,
  errors,
}: {
  errors?: ListOfErrors;
  id?: string;
}) {
  const errorsToRender = errors?.filter(Boolean);
  if (!errorsToRender?.length) return null;
  return (
    <ul id={id} className="flex flex-col gap-1">
      {errorsToRender.map((e) => (
        <li key={e} className="text-[10px] text-foreground-destructive">
          {e}
        </li>
      ))}
    </ul>
  );
}

export function Field({
  labelProps,
  inputProps,
  errors,
  className,
  endEl,
  tooltip,
}: {
  labelProps: React.LabelHTMLAttributes<HTMLLabelElement>;
  inputProps: React.InputHTMLAttributes<HTMLInputElement>;
  errors?: ListOfErrors;
  className?: string;
  endEl?: React.ReactNode;
  tooltip?: {
    id: string;
    icon: IconName;
    message: string;
  };
}) {
  const fallbackId = useId();
  const id = inputProps.id ?? fallbackId;
  const errorId = errors?.length ? `${id}-error` : undefined;
  return (
    <div className={className}>
      <div className="flex flex-col gap-1.5">
        <div className="flex items-baseline gap-2">
          <Label htmlFor={id} {...labelProps} />
          {tooltip ? (
            <TooltipProvider>
              <Tooltip>
                <TooltipTrigger asChild>
                  <div id={tooltip.id}>
                    <Icon name={tooltip.icon} />
                  </div>
                </TooltipTrigger>
                <TooltipContent align="end" side="bottom">
                  {tooltip.message}
                </TooltipContent>
              </Tooltip>
            </TooltipProvider>
          ) : null}
        </div>
        <div className="flex gap-1">
          <Input
            id={id}
            aria-invalid={errorId ? true : undefined}
            aria-describedby={errorId}
            {...inputProps}
          />
          {endEl}
        </div>
      </div>
      <div className="min-h-[32px] px-4 pb-3 pt-1">
        {errorId ? <ErrorList id={errorId} errors={errors} /> : null}
      </div>
    </div>
  );
}

export function TextareaField({
  labelProps,
  textareaProps,
  errors,
  className,
}: {
  labelProps: React.LabelHTMLAttributes<HTMLLabelElement>;
  textareaProps: React.TextareaHTMLAttributes<HTMLTextAreaElement>;
  errors?: ListOfErrors;
  className?: string;
}) {
  const fallbackId = useId();
  const id = textareaProps.id ?? textareaProps.name ?? fallbackId;
  const errorId = errors?.length ? `${id}-error` : undefined;
  return (
    <div className={className}>
      <div className="flex flex-col gap-1.5">
        <Label htmlFor={id} {...labelProps} />
        <Textarea
          id={id}
          aria-invalid={errorId ? true : undefined}
          aria-describedby={errorId}
          {...textareaProps}
        />
      </div>
      <div className="min-h-[32px] px-4 pb-3 pt-1">
        {errorId ? <ErrorList id={errorId} errors={errors} /> : null}
      </div>
    </div>
  );
}

export function CheckboxField({
  labelProps,
  buttonProps,
  errors,
  className,
  tooltip,
}: {
  labelProps: JSX.IntrinsicElements['label'];
  buttonProps: CheckboxProps & {
    name: string;
    form: string;
    value?: string;
  };
  errors?: ListOfErrors;
  className?: string;
  tooltip?: {
    id: string;
    icon: IconName;
    message: string;
  };
}) {
  const { key, defaultChecked, ...checkboxProps } = buttonProps;
  const fallbackId = useId();
  const checkedValue = buttonProps.value ?? 'on';
  const input = useInputControl({
    key,
    name: buttonProps.name,
    formId: buttonProps.form,
    initialValue: defaultChecked ? checkedValue : undefined,
  });
  const id = buttonProps.id ?? fallbackId;
  const errorId = errors?.length ? `${id}-error` : undefined;

  return (
    <div className={className}>
      <div className="flex items-center gap-2">
        <Checkbox
          {...checkboxProps}
          id={id}
          aria-invalid={errorId ? true : undefined}
          aria-describedby={errorId}
          checked={input.value === checkedValue}
          onCheckedChange={(state) => {
            input.change(state.valueOf() ? checkedValue : '');
            buttonProps.onCheckedChange?.(state);
          }}
          onFocus={(event) => {
            input.focus();
            buttonProps.onFocus?.(event);
          }}
          onBlur={(event) => {
            input.blur();
            buttonProps.onBlur?.(event);
          }}
          type="button"
        />
        <label
          htmlFor={id}
          {...labelProps}
          className="self-center text-body-xs text-muted-foreground"
        />
        {tooltip ? (
          <TooltipProvider>
            <Tooltip>
              <TooltipTrigger asChild>
                <div id={tooltip.id}>
                  <Icon name={tooltip.icon} />
                </div>
              </TooltipTrigger>
              <TooltipContent align="end" side="bottom">
                {tooltip.message}
              </TooltipContent>
            </Tooltip>
          </TooltipProvider>
        ) : null}
      </div>
      <div className="px-4 pb-3 pt-1">
        {errorId ? <ErrorList id={errorId} errors={errors} /> : null}
      </div>
    </div>
  );
}

// TODO: When conform throws an error
// focus the styled select instead of
// the real select input
export function SelectField({
  labelProps,
  selectProps,
  errors,
  className,
}: {
  labelProps: React.LabelHTMLAttributes<HTMLLabelElement>;
  selectProps: {
    root?: React.ComponentProps<typeof Select>;
    trigger?: React.ComponentProps<typeof SelectTrigger>;
    options: Array<string | { name: string; value: string }>;
  };
  errors?: ListOfErrors;
  className?: string;
}) {
  const fallbackId = useId();
  const id = selectProps.trigger?.id ?? fallbackId;
  const errorId = errors?.length ? `${id}-error` : undefined;
  return (
    <div className={className}>
      <div className="flex flex-col gap-1.5">
        <Label htmlFor={id} {...labelProps} />
        <Select
          aria-invalid={errorId ? true : undefined}
          aria-describedby={errorId}
          {...selectProps.root}
        >
          <SelectTrigger id={id} {...selectProps.trigger}>
            <SelectValue />
          </SelectTrigger>
          <SelectContent>
            {selectProps.options
              .map((option) =>
                typeof option === 'string'
                  ? { name: option, value: option }
                  : option,
              )
              .map((option) => (
                <SelectItem key={option.value} value={option.value}>
                  {option.name}
                </SelectItem>
              ))}
          </SelectContent>
        </Select>
      </div>
      <div className="min-h-[32px] px-4 pb-3 pt-1">
        {errorId ? <ErrorList id={errorId} errors={errors} /> : null}
      </div>
    </div>
  );
}

export function MultiSelectField({
  labelProps,
  selectProps,
  errors,
  className,
}: {
  labelProps: React.LabelHTMLAttributes<HTMLLabelElement>;
  selectProps: MultiSelectProps;
  errors?: ListOfErrors;
  className?: string;
}) {
  const fallbackId = useId();
  const id = selectProps.id ?? fallbackId;
  const errorId = errors?.length ? `${id}-error` : undefined;
  return (
    <div className={className}>
      <div className="flex flex-col gap-1.5">
        <Label htmlFor={id} {...labelProps} />
        <MultiSelect
          {...selectProps}
          id={id}
          inputProps={selectProps.inputProps}
          aria-invalid={errorId ? true : undefined}
          aria-describedby={errorId}
        />
      </div>
      <div className="min-h-[32px] px-4 pb-3 pt-1">
        {errorId ? <ErrorList id={errorId} errors={errors} /> : null}
      </div>
    </div>
  );
}
